import { rest } from "msw";
import { API_URL } from "utils/environmentVariables";
import {
  mockComplianceOverview,
  mockComplianceProcess,
  mockComplianceScoring,
  mockComplianceResources,
  mockComplianceFaqs,
  mockFavorites,
  suggestedUserData,
  mockActiveAsset,
  mockInactiveAsset,
  mockDeletedUser,
  mockDeletedGroup,
  mockDeleteUser,
  mockFeedbackOptions,
  mockSentFCPackageResponse,
  filters,
  savedSearches,
  suggestedSearch,
  assetSearchResults,
  complianceSearchResults,
  guidelinesSearchResults,
  trainingSearchResults,
  mockSentFCPackageResponseNoDlHistory,
  singleSavedSearch,
  mockRetailCenter,
  mockRetailBrandedEnvironment,
  mockRetailDesignAdvisories,
  mockCountries,
  mockEmploymentTitles,
  mockAccountTypes,
  mockCategories,
  mockSubcategories,
  mockTags,
  relatedAssets,
  mockChannelsUses,
  assetDeactivated,
  mockFCGetPackagesResponse,
  mockFCGetPackagesEmptyResponse,
  mockAllEmailTemplates,
  mockSelectedEmailTemplate,
  recentSearches,
  mockAccessRequestList,
  mockAccessRequestListPage2,
  mockAccessRequestList25,
  mockRequestDetailsPending,
  mockRequestDetailsPendingConditionals,
  mockRequestDetailsDeclined,
  mockRequestDetailsApproved,
  mockMyDownloads5,
  mockMyDownloads15,
  mockMyDownloads5Page2,
  mockMyDownloadsNoResults,
  renamedSavedSearches,
  deletedFirstSavedSearches,
  mockFavoritesPageFolders,
  mockFavoritesPageItems,
  mockFavoritesPageItemsSwitchTabs,
  assetWithFiles,
  relatedSearch,
  twoRecentSearches,
  unifiedSearchResults,
  retailSearchResults,
  savedSearchWithAvailabilityFiltersAndTag,
  savedSearchGuidelinesWithAvailabilityFilters,
  savedSearchTemplateWithAvailabilityFilters,
  templatesSearchResults,
  allPackagingManagerProjectsReporting,
  emptyPackagingManagerProjectsReporting,
  packagingManagerProjectsReporting25,
  allPackagingManagerProjectsReportingPage2,
  packagingManagerAwsPolicy,
  pmGroupTypes,
  pmGroupPermissions,
  pmProjectBriefs,
  pmAllProjectTasks,
  pmTaskWithDueDateAssignedToAndFiles,
  pmTaskDetailsNoDueDateAssignedToOrFiles, pmTaskChecklistComplete, pmTaskCompleted, pmProjectInstanceNewProduct, pmNewTaskWithFormNoFiles, pmNewTaskWithChecklist, pmFormBlueprintTypes, pmDefaultTaskTypes, pmProjectInstancePrepaid, projectForFilesTab, pmFilesResponse, pmFilesPage2Response, pmFilesAfterUploading, pmEmptyFilesResponse, pmFileWithTwoVersions, pmProjectTeamsInitial, pmGroupTypesResponse, pmMessagesResponse, pmEmptyMessagesResponse, pmFilesForHomeTab, pmTasksForHomeTab, pmMessagesPage2, pmProjectsResponse, pmProjectsResponsePage2, pmProjectTypes,
} from "./mockRTKData";
import {
  assetSearchResultsLarge,
  assetSearchResultsSmall, unifiedSearchAssetResultsLarge, unifiedSearchResultsLarge,
} from "./mockSearchResultsData";

export const handlers = {
  global: rest.get(`${API_URL}/cms/global`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  acceptUsageRights: rest.put(
    `/asset/*/usage-rights/accept`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json({}), ctx.delay(150));
    }
  ),
  acceptUsageRightsError: rest.put(
    `/asset/*/usage-rights/accept`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}), ctx.delay(150));
    }
  ),
  accessRequestMutation: rest.post(
    `${API_URL}/assets/access-requests/*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json({}), ctx.delay(150));
    }
  ),
  accessRequestMutationError: rest.post(
    `${API_URL}/assets/access-requests/*`,
    (req, res, ctx) => {
      return res(ctx.status(500), ctx.json({}), ctx.delay(150));
    }
  ),
  adminGroups: rest.get(`/users/groups`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({ data: [{ Id: 0, Name: "Test Group", Active: true }] })
    );
  }),
  adminCreateGroup: rest.post(`/users/groups`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}), ctx.delay(150));
  }),
  adminCreateGroupError: rest.post(`/users/groups`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}), ctx.delay(150));
  }),
  complianceOverview: rest.get(
    `${API_URL}/cms/compliance-overview`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockComplianceOverview));
    }
  ),
  complianceOverviewError: rest.get(
    `${API_URL}/cms/compliance-overview`,
    (req, res, ctx) => {
      return res.networkError("Failed to connect");
    }
  ),

  complianceProcess: rest.get(
    `${API_URL}/cms/compliance-process`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockComplianceProcess));
    }
  ),
  complianceProcessError: rest.get(
    `${API_URL}/cms/compliance-process`,
    (req, res, ctx) => {
      return res.networkError("Failed to connect");
    }
  ),

  complianceScoring: rest.get(
    `${API_URL}/cms/compliance-scoring`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockComplianceScoring));
    }
  ),
  complianceScoringError: rest.get(
    `${API_URL}/cms/compliance-scoring`,
    (req, res, ctx) => {
      return res.networkError("Failed to connect");
    }
  ),

  complianceResources: rest.get(
    `${API_URL}/cms/compliance-resources`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockComplianceResources));
    }
  ),
  complianceResourcesError: rest.get(
    `${API_URL}/cms/compliance-resources`,
    (req, res, ctx) => {
      return res.networkError("Failed to connect");
    }
  ),

  complianceFaqs: rest.get(
    `${API_URL}/cms/compliance-faqs`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockComplianceFaqs));
    }
  ),
  complianceFaqsError: rest.get(
    `${API_URL}/cms/compliance-faqs`,
    (req, res, ctx) => {
      return res.networkError("Failed to connect");
    }
  ),
  retailCenter: rest.get(`${API_URL}/cms/retail-overview`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockRetailCenter));
  }),
  retailCenterError: rest.get(
    `${API_URL}/cms/retail-overview`,
    (req, res, ctx) => {
      return res.networkError("Failed to connect");
    }
  ),
  retailBrandedEnvironments: rest.get(
    `${API_URL}/cms/retail-branded-environments-design-guide`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockRetailBrandedEnvironment));
    }
  ),
  retailBrandedEnvironmentsError: rest.get(
    `${API_URL}/cms/retail-branded-environments-design-guide`,
    (req, res, ctx) => {
      return res.networkError("Failed to connect");
    }
  ),
  retailDesignAdvisories: rest.get(
    `${API_URL}/cms/retail-branded-environments-design-advisories`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockRetailDesignAdvisories));
    }
  ),
  retailDesignAdvisoriesError: rest.get(
    `${API_URL}/cms/retail-branded-environments-design-advisories`,
    (req, res, ctx) => {
      return res.networkError("Failed to connect");
    }
  ),
  getAssetDownloadUrl: rest.get(`/assets/*/download`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ data: "response" }));
  }),
  getAssetDownloadUrlError: rest.get(`/assets/*/download`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}), ctx.delay(150));
  }),
  getFavorites: rest.get(`/favorites/folders`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockFavorites));
  }),
  getFavoritesEmpty: rest.get(`/favorites/folders`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json([]));
  }),
  getFavoritesError: rest.get(`/favorites/folders`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}), ctx.delay(150));
  }),
  getFavoritesPageFolders: rest.get(`/favorites/favorites-page-folders`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockFavoritesPageFolders));
  }),
  getFavoritesPageFoldersEmpty: rest.get(`/favorites/favorites-page-folders`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json([]));
  }),
  getFavoritesPageFoldersError: rest.get(`/favorites/favorites-page-folders`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}), ctx.delay(150));
  }),
  getSuggestedUser: rest.get(`/user/suggest`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(suggestedUserData));
  }),
  getSuggestedUserError: rest.get(`/user/suggest`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  addFavorites: rest.post(`/favorites`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  addFavoritesError: rest.post(`/favorites`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  removeFavorites: rest.delete(`/favorites*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  removeFavoritesError: rest.delete(`/favorites*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  expireAsset: rest.put(`/asset/*/expires-on/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  expireAssetError: rest.put(`/asset/*/expires-on/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  shareAssets: rest.post(`/asset/share`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}), ctx.delay(300));
  }),
  shareAssetsError: rest.post(`/asset/share`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}), ctx.delay(150));
  }),
  activateAsset: rest.put(`/asset/*/is-active/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ mockActiveAsset }));
  }),
  activateAssetError: rest.put(`/asset/*/is-active/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  deactivateAsset: rest.put(`/asset/*/is-active/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ mockInactiveAsset }));
  }),
  deactivateAssetError: rest.put(`/asset/*/is-active/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  deleteUser: rest.put(`/admin/users/*/delete`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ mockDeletedUser }));
  }),
  deleteUserError: rest.put(`/admin/users/*/delete`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  deleteGroup: rest.put(`/users/groups/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ mockDeletedGroup }));
  }),
  deleteGroupError: rest.put(`/users/groups/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  deleteFavoriteFolder: rest.delete(
    `/favorites/delete-folder*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json({}));
    }
  ),
  deleteFavoriteFolderError: rest.delete(
    `/favorites/delete-folder*`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  getFeedbackOptions: rest.get(
    `/tracking/feedback/reasons`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json({ mockFeedbackOptions }));
    }
  ),
  getFeedbackOptionsError: rest.get(
    `/tracking/feedback/reasons`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  sendContact: rest.post(`/distribution-lists/*/notify`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}), ctx.delay(300));
  }),
  sendContactError: rest.post(
    `/distribution-lists/*/notify`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}), ctx.delay(150));
    }
  ),
  sendFeedback: rest.post(`/feedback`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  sendFeedbackError: rest.post(`/feedback`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  tracking: rest.post(`/tracking`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  trackingError: rest.post(`/tracking`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  trackFeedback: rest.post(`/tracking/feedback`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  trackFeedbackError: rest.post(`/tracking/feedback`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  updateUser: rest.put(`/users`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ mockDeleteUser }));
  }),
  updateUserError: rest.put(`/users`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getFileDownloadUrl: rest.get(`/assets/*/download/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json("http://www.google.com"));
  }),
  getFileDownloadUrlError: rest.get(`/assets/*/download/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}), ctx.delay(300));
  }),
  deleteFileCourierPackage: rest.get(
    `/file-courier/api/package/delete/*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json({}));
    }
  ),
  deleteFileCourierPackageError: rest.get(
    `/file-courier/api/package/delete/*`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  getSentFileCourierPackageDetails: rest.get(
    `/file-courier/api/package/sentdetails`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockSentFCPackageResponse));
    }
  ),
  getSentFileCourierPackageDetailsNoDlHistory: rest.get(
    `/file-courier/api/package/sentdetails`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(mockSentFCPackageResponseNoDlHistory)
      );
    }
  ),
  getSentFileCourierPackageDetailsError: rest.get(
    `/file-courier/api/package/sentdetails`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  extendFileCourierPackage: rest.get(
    `/file-courier/api/package/extend`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json("9999-12-31T04:00:00+00:00"));
    }
  ),
  extendFileCourierPackageError: rest.get(
    `/file-courier/api/package/extend`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  uploadFileCourierFile: rest.post(
    `/file-courier/api/upload/post`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json("Files uploaded successfully"));
    }
  ),
  uploadFileCourierFileError: rest.post(
    `/file-courier/api/upload/post`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  sendFileCourierPackage: rest.post(
    `/file-courier/api/package/post`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json("Files sent successfully"));
    }
  ),
  sendFileCourierPackageError: rest.post(
    `/file-courier/api/package/post`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  deleteFileCourierFile: rest.delete(
    `/file-courier/api/upload/delete`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json("Files deleted successfully"),
        ctx.delay(150)
      );
    }
  ),
  deleteFileCourierFileError: rest.delete(
    `/file-courier/api/upload/delete`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}), ctx.delay(150));
    }
  ),
  getFilters: rest.get(`/filters/all`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(filters));
  }),
  getFiltersError: rest.get(`/filters/all`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getCategories: rest.get(`/filters/categories`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockCategories));
  }),
  updateCategory: rest.put(`/filters/categories/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  updateCategoryError: rest.put(`/filters/categories/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  newCategory: rest.post(`/filters/categories`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ id: "test-id" }));
  }),
  newCategoryError: rest.post(`/filters/categories`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getSubcategories: rest.get(`/filters/subcategories*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockSubcategories));
  }),
  getSubcategoryEmpty: rest.get(`/filters/subcategories*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json([]));
  }),
  updateSubcategory: rest.put(`/filters/subcategories/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  updateSubcategoryError: rest.put(
    `/filters/subcategories/*`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  newSubcategory: rest.post(`/filters/subcategories`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ id: "test-id" }));
  }),
  newSubcategoryError: rest.post(`/filters/subcategories`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getTags: rest.get(`/filters/tags*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockTags));
  }),
  getTagsEmpty: rest.get(`/filters/tags*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json([]));
  }),
  updateTag: rest.put(`/filters/tags/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  updateTagError: rest.put(`/filters/tags/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  newTag: rest.post(`/filters/tags`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ id: "test-id" }));
  }),
  newTagError: rest.post(`/filters/tags`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getSuggestedSearches: rest.get(`/unified-search/did-you-mean`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(suggestedSearch));
  }),
  getSuggestedSearchesError: rest.get(`/unified-search/did-you-mean`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getSuggestedSearchesEmpty: rest.get(
    `/unified-search/did-you-mean`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json([]));
    }
  ),
  getRecentSearches: rest.get(`/search/recent`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(recentSearches));
  }),
  getRecentSearchesError: rest.get(`/search/recent`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getRecentSearchesAfterDeleting: rest.get(`/search/recent`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(twoRecentSearches));
  }),
  getRecentSearchesEmpty: rest.get(`/search/recent`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json([]));
  }),
  deleteRecentSearch: rest.delete(`/search/recent/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  searchUnifiedAllResults: rest.get(`/unified/search`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(unifiedSearchResults));
  }),
  searchUnifiedAssetResults: rest.get(`/unified/search`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(assetSearchResults));
  }),
  searchUnifiedGuidelineResults: rest.get(`/unified/search`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(guidelinesSearchResults));
  }),
  searchUnifiedComplianceResults: rest.get(`/unified/search`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(complianceSearchResults));
  }),
  searchUnifiedRetailResults: rest.get(`/unified/search`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(retailSearchResults));
  }),
  searchUnifiedTemplateResults: rest.get(`/unified/search`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(templatesSearchResults));
  }),
  searchUnifiedTrainingResults: rest.get(`/unified/search`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(trainingSearchResults));
  }),
  searchUnifiedError: rest.get(`/unified/search`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  searchUnifiedNoResults: rest.get(`/unified/search`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ results: [] }));
  }),
  searchAssetsSmall: rest.get(`/unified/search`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(unifiedSearchResults));
  }),
  searchAssetsLarge: rest.get(`/unified/search`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(assetSearchResultsLarge));
  }),
  searchUnifiedLarge: rest.get(`/unified/search`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(unifiedSearchResultsLarge));
  }),
  searchUnifiedAssetsLarge: rest.get(`/unified/search`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(unifiedSearchAssetResultsLarge));
  }),
  relatedSearches: rest.get(`/unified-search/autocomplete*`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(relatedSearch)
    );
  }),
  relatedSearchesError: rest.get(`/unified-search/autocomplete*`, (req, res, ctx) => {
    return res(
      ctx.status(400),
      ctx.json({})
    );
  }),
  relatedSearchesEmpty: rest.get(`/unified-search/autocomplete*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json([]));
  }),
  searchAssetsEmpty: rest.get(`/unified/search`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ total: 0, results: [] }));
  }),
  searchCompliance: rest.get(`/cms/compliance/search`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(complianceSearchResults));
  }),
  searchComplianceEmpty: rest.get(`/cms/compliance/search`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ results: [] }));
  }),
  searchGuidelines: rest.get(`/guidelines/search`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(guidelinesSearchResults));
  }),
  searchGuidelinesEmpty: rest.get(`/guidelines/search`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ results: [] }));
  }),
  searchTraining: rest.get(`/cms/training-videos/search`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(trainingSearchResults));
  }),
  searchTrainingEmpty: rest.get(
    `/cms/training-videos/search`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json({ results: [] }));
    }
  ),
  downloadFileCourierFile: rest.get(
    `/file-courier/api/download/getfile/*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json("file url"));
    }
  ),
  downloadFileCourierFileError: rest.get(
    `/file-courier/api/download/getfile/*`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  downloadFileCourierPackage: rest.get(
    `/file-courier/api/download/getzip/*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json("file url"));
    }
  ),
  downloadFileCourierPackageError: rest.get(
    `/file-courier/api/download/getzip/*`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  getSavedSearches: rest.get(`/favorites/savedSearches`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(savedSearches));
  }),
  getSavedSearchesEmpty: rest.get(
    `/favorites/savedSearches`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json([]));
    }
  ),
  getFirstDeletedSavedSearches: rest.get(
    `/favorites/savedSearches`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(deletedFirstSavedSearches));
    }
  ),
  getSavedSearchesSingle: rest.get(
    `/favorites/savedSearches`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json([savedSearches[0]]));
    }
  ),
  getUnifiedSavedSearchAsset: rest.get(
    `/favorites/savedSearches/*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(savedSearchWithAvailabilityFiltersAndTag));
    }
  ),
  getUnifiedSavedSearchGuidelines: rest.get(
    `/favorites/savedSearches/*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(savedSearchGuidelinesWithAvailabilityFilters));
    }
  ),
  getUnifiedSavedSearchTemplates:  rest.get(
    `/favorites/savedSearches/*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(savedSearchTemplateWithAvailabilityFilters));
    }
  ),
  deleteSavedSearch: rest.delete(
    `/favorites/savedSearches/*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json({}));
    }
  ),
  deleteSavedSearchError: rest.delete(
    `/favorites/savedSearches/*`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  renameSavedSearch: rest.put(`/favorites/savedSearches/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  renameSavedSearchError: rest.put(
    `/favorites/savedSearches/*`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  getRenamedSavedSearches: rest.get(
    `/favorites/savedSearches`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(renamedSavedSearches));
    }
  ),
  saveSearch: rest.post(`/favorites/savedSearches`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(singleSavedSearch));
  }),
  saveSearchError: rest.post(`/favorites/savedSearches`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  shareSavedSearch: rest.post(
    `/favorites/savedSearches/share`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json({}));
    }
  ),
  shareSavedSearchError: rest.post(
    `/favorites/savedSearches/share`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  getSavedSearch: rest.get(
    `favorites/savedSearches/test-id`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          searchObject:
            '{"Query":null,"Filters":[{"Type":"subcategory","Value":"76007f55-b998-4883-8e53-64c5ecec4a51"},{"Type":"tag","Value":"3d8d9269-63a0-437f-90ec-f70204d5c10f"}],"IsRestricted":true,"IsExpired":false,"ExcludeWithinExpiredDate":null}',
        })
      );
    }
  ),
  getAssetSavedSearch: rest.get(
    `favorites/savedSearches/test-id`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          searchObject:
            '{"Query":null, "Filters":[{"Type":"subcategory","Value":"76007f55-b998-4883-8e53-64c5ecec4a51"},{"Type":"tag","Value":"8e04ac67-b08a-4f88-ad39-194dd6d80f02"}], "IsRestricted":true,"IsExpired":true,"ExcludeWithinExpiredDate":"2924-12-31T00:00:00Z"}',
          searchType: "asset"
        })
      );
    }
  ),
  getTemplateSavedSearch: rest.get(
    `favorites/savedSearches/test-id`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          searchObject:
            '{"Query":null, "Filters":[{"Type":"subcategory","Value":"6e2909ba-42ba-45e4-b1b4-6f273d440d87"},{"Type":"tag","Value":"c8f85b01-daee-47f1-a10b-e4b771d71837"}], "IsRestricted":true,"IsExpired":true,"ExcludeWithinExpiredDate":"2924-12-31T00:00:00Z"}',
          searchType: "template"
        })
      );
    }
  ),
  getRetailSavedSearch: rest.get(
    `favorites/savedSearches/test-id`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          searchObject:
            '{"Query":null, "Filters":[{"Type":"subcategory","Value":"974d55ee-0566-4c21-8e91-8cdfae764bc2"},{"Type":"tag","Value":"8a14cfe4-4171-436e-8724-ae35afdd422a"}], "IsRestricted":true,"IsExpired":true,"ExcludeWithinExpiredDate":"2924-12-31T00:00:00Z"}',
          searchType: "retail"
        })
      );
    }
  ),
  getSearchTypeAssetCategoryAllSavedSearch: rest.get(
    `favorites/savedSearches/test-id`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          searchObject:
            '{"Query":null, "Filters":[{"Type":"subcategory","Value":"All"}], "IsRestricted":true,"IsExpired":true,"ExcludeWithinExpiredDate":"2924-12-31T00:00:00Z"}',
          searchType: "asset"
        })
      );
    }
  ),
  getSearchTypeAssetOnlyAvailabilityFiltersSavedSearch: rest.get(
    `favorites/savedSearches/test-id`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          searchObject:
            '{"Query":null, "Filters":[], "IsRestricted":true,"IsExpired":true,"ExcludeWithinExpiredDate":"2924-12-31T00:00:00Z"}',
          searchType: "asset"
        })
      );
    }
  ),
  getGuidelineSavedSearch: rest.get(
    `favorites/savedSearches/test-id`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          searchObject:
            '{"Query":null, "Filters":[], "IsRestricted":null,"IsExpired":null,"ExcludeWithinExpiredDate":null}',
          searchType: "guideline"
        })
      );
    }
  ),
  getComplianceWith2AvailabilityFiltersSavedSearch: rest.get(
    `favorites/savedSearches/test-id`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          searchObject:
            '{"Query":null, "Filters":[], "IsRestricted":true,"IsExpired":false,"ExcludeWithinExpiredDate":"2924-12-31T00:00:00Z"}',
          searchType: "compliance"
        })
      );
    }
  ),
  getEmptyFiltersSavedSearch: rest.get(
    `favorites/savedSearches/test-id`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          searchObject:
            '{"Query":"test", "Filters":[], "IsRestricted":true,"IsExpired":false,"ExcludeWithinExpiredDate":null}',
          searchType: "all"
        })
      );
    }
  ),
  getSavedSearchError: rest.get(
    `favorites/savedSearches/test-id`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  requestAsset: rest.post(`/forms/request-asset`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  requestAssetError: rest.post(`/forms/request-asset`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  adminAssetSearch: rest.get(`/asset/adminsearch`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}), ctx.delay(150));
  }),
  adminAssetSearchError: rest.get(`/asset/adminsearch`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  adminGetGroup: rest.get(`/users/groups/*`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({ Id: "test-id", Name: "Test Group", Status: "Active" })
    );
  }),
  adminGetGroupEmpty: rest.get(`/users/groups/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  adminGetGroupError: rest.get(`/users/groups/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  adminUpdateGroup: rest.put(`/users/groups/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  adminUpdateGroupError: rest.put(`/users/groups/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getAccountTypes: rest.get(`/admin/users/accountTypes`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockAccountTypes));
  }),
  adminGetUser: rest.get(`/admin/users/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockDeleteUser));
  }),
  adminGetUserError: rest.get(`/admin/users/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  adminGetUserEmpty: rest.get(`/admin/users/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  adminUpdateUser: rest.put(`/admin/users/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockDeleteUser));
  }),
  adminUpdateUserError: rest.put(`/admin/users/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json(mockDeleteUser));
  }),
  getCountries: rest.get(`/countries`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockCountries));
  }),
  getEmploymentTitles: rest.get(`/employmentTitles`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockEmploymentTitles));
  }),
  getSimilarAssets: rest.get(`/asset/*/similar`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(relatedAssets));
  }),
  getRelatedAssets: rest.get(`/asset/*/related`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(relatedAssets));
  }),
  getNoSimilarAssets: rest.get(`/asset/*/similar`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json([]));
  }),
  getNoRelatedAssets: rest.get(`/asset/*/related`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json([]));
  }),
  getChannelsUses: rest.get(
    `/assets/access-requests/channels-uses`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockChannelsUses));
    }
  ),
  // getAssetEditPending: rest.get(`/asset/*/edit`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(mockAssetEditData(true)));
  // }),
  // getAssetEdit: rest.get(`/asset/*/edit`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(mockAssetEditData(false)));
  // }),
  getFileCourierSentPackages: rest.get(
    `/file-courier/api/package/sent`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockFCGetPackagesResponse));
    }
  ),
  getFileCourierSentNoPackages: rest.get(
    `/file-courier/api/package/sent`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockFCGetPackagesEmptyResponse));
    }
  ),
  getFileCourierSentPackagesError: rest.get(
    `/file-courier/api/package/sent`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  getFileCourierReceivedPackages: rest.get(
    `/file-courier/api/package/received`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockFCGetPackagesResponse));
    }
  ),
  getFileCourierReceivedNoPackages: rest.get(
    `/file-courier/api/package/received`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockFCGetPackagesEmptyResponse));
    }
  ),
  getFileCourierReceivedPackagesError: rest.get(
    `/file-courier/api/package/received`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  getAllEmailTemplates: rest.get(
    `/admin/email-template/all`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockAllEmailTemplates));
    }
  ),
  getAllEmailTemplatesError: rest.get(
    `/admin/email-template/all`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  getEmailTemplateById: rest.get(`/admin/email-template/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockSelectedEmailTemplate));
  }),
  getEmailTemplateByIdError: rest.get(
    `/admin/email-template/*`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  callApproveFile: rest.put(
    `/asset/*/assign/file-approval`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json({}));
    }
  ),
  callApproveFileError: rest.put(
    `/asset/*/assign/file-approval`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  callCreateAsset: rest.post(`/asset`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  callCreateAssetError: rest.post(`/asset`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  callAssetUpdate: rest.put(`/asset/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  callAssetUpdateError: rest.put(`/asset/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getApproverAccessRequests: rest.get(
    `/assets/access-requests/approver*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockAccessRequestList));
    }
  ),
  getApproverAccessRequests25: rest.get(
    `/assets/access-requests/approver*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockAccessRequestList25));
    }
  ),
  getApproverAccessRequestsPage2: rest.get(
    `/assets/access-requests/approver*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockAccessRequestListPage2));
    }
  ),
  getApproverAccessRequestsError: rest.get(
    `/assets/access-requests/approver*`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),

  getRequestorAccessRequests: rest.get(
    `/assets/access-requests/requestor*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockAccessRequestList));
    }
  ),
  getRequestorAccessRequests25: rest.get(
    `/assets/access-requests/requestor*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockAccessRequestList25));
    }
  ),
  getRequestorAccessRequestsPage2: rest.get(
    `/assets/access-requests/requestor*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockAccessRequestListPage2));
    }
  ),
  getRequestorAccessRequestsError: rest.get(
    `/assets/access-requests/requestor*`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  getRequestDetails: rest.get(`/assets/access-requests/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockRequestDetailsPending));
  }),
  getRequestDetailsApproved: rest.get(
    `/assets/access-requests/*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockRequestDetailsApproved));
    }
  ),
  getRequestDetailsDeclined: rest.get(
    `/assets/access-requests/*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockRequestDetailsDeclined));
    }
  ),
  getRequestDetailsConditionalFields: rest.get(
    `/assets/access-requests/*`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(mockRequestDetailsPendingConditionals)
      );
    }
  ),
  getRequestDetailsError: rest.get(
    `/assets/access-requests/*`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  declineAccessRequest: rest.put(
    `/assets/access-requests/decline/*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json({}));
    }
  ),
  declineAccessRequestError: rest.put(
    `/assets/access-requests/decline/*`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  approveAccessRequest: rest.put(
    `/assets/access-requests/approve/*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json({}));
    }
  ),
  approveAccessRequestError: rest.put(
    `/assets/access-requests/approve/*`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  getDownloadedAssets: rest.get(`/assets/downloaded*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockMyDownloads5));
  }),
  getDownloadedAssets15: rest.get(`/assets/downloaded*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockMyDownloads15));
  }),
  getDownloadedAssetsPage2: rest.get(`/assets/downloaded*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockMyDownloads5Page2));
  }),
  getDownloadedAssetsNoResults: rest.get(
    `/assets/downloaded*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockMyDownloadsNoResults));
    }
  ),
  getDownloadedAssetsError: rest.get(`/assets/downloaded*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getUploadedAssets: rest.get(`/assets/uploaded*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockMyDownloads5));
  }),
  getUploadedAssets15: rest.get(`/assets/uploaded*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockMyDownloads15));
  }),
  getUploadedAssetsPage2: rest.get(`/assets/uploaded*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockMyDownloads5Page2));
  }),
  getUploadedAssetsNoResults: rest.get(`/assets/uploaded*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockMyDownloadsNoResults));
  }),
  getUploadedAssetsError: rest.get(`/assets/uploaded*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getSavedSearchesError: rest.get(
    `/favorites/savedSearches`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}));
    }
  ),
  getFavoritesPageItems: rest.get(`/favorites/0dc754ab-74b9-44af-8fc2-86222b8200b9`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockFavoritesPageItems));
  }),
  getFavoritesPageItemsSwitchTabs: rest.get(`/favorites/11bfb98d-9d7b-4693-a169-ecca989905d0`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockFavoritesPageItemsSwitchTabs));
  }),
  getFavoritesPageItemsEmpty: rest.get(`/favorites/0dc754ab-74b9-44af-8fc2-86222b8200b9`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json([]));
  }),
  getFavoritesPageItemsError: rest.get(`/favorites/0dc754ab-74b9-44af-8fc2-86222b8200b9`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}), ctx.delay(150));
  }),
  // make sure these 2 stay below any other mocked api calls that start off with asset!!
  getActivatedAsset: rest.get(`/asset*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(assetWithFiles));
  }),
  getAssetError: rest.get(`/asset*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getFavoriteAssets: rest.get(`/asset*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(relatedAssets));
  }),
  getFavoriteAssetsEmpty: rest.get(`/asset*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json([]));
  }),
  awsPolicy: rest.get(`/Aws/policy`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ bucket: "test" }));
  }),
  awsUpload: rest.post(`https://test.s3.amazonaws.com/`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  rekognition: rest.get(`/rekognition`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json([{ id: 0, tags: ["test-one"] }]));
  }),
  getAllPackagingProjects: rest.get(`/packaging-manager/project/all*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(allPackagingManagerProjectsReporting));
  }),
  get25PackagingProjects: rest.get(`/packaging-manager/project/all*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(packagingManagerProjectsReporting25));
  }),
  getPage2PackagingProjects: rest.get(`/packaging-manager/project/all*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(allPackagingManagerProjectsReportingPage2));
  }),
  getEmptyPackagingProjects: rest.get(`/packaging-manager/project/all*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(emptyPackagingManagerProjectsReporting));
  }),
  getPackagingProjectsError: rest.get(`/packaging-manager/project/all*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  exportPackagingProjects: rest.get(`/packaging-manager/project-report/projects/export`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  exportPackagingProjectsError: rest.get(`/packaging-manager/project-report/projects/export`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getPackagingManagerAwsPolicy: rest.get(`/Aws/workflow`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({packagingManagerAwsPolicy}));
  }),
  getPackagingManagerGroups: rest.get(`users/groups`, (req, res, ctx) => {
    const url = new URL(req.url)
    const q = url.searchParams.get('ClientCode')
    if (q === "PKM") {
      return res(ctx.status(200), ctx.json(pmGroupTypesResponse));
    }
  }),
  getPackagingManagerGroupsError: rest.get(`users/groups`, (req, res, ctx) => {
    const url = new URL(req.url)
    const q = url.searchParams.get('ClientCode')
    if (q === "PKM") {
      return res(ctx.status(400), ctx.json({}));
    }
  }),
  getPmPermissions: rest.get(`/workflow/groups/permissions/PKM`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmGroupPermissions));
  }),
  getPmPermissionsError: rest.get(`/workflow/groups/permissions/PKM`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  updatePmPermissions: rest.put(
    `/workflow/groups/update/*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json("85da4af4-4320-4969-b4f9-90a8596edb55"), ctx.delay(150));
    }
  ),
  updatePmPermissionsError: rest.put(
    `/workflow/groups/update/*`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}), ctx.delay(150));
    }
  ),
  getPmGroupTypes: rest.get(`/workflow/groups/group-types/PKM`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmGroupTypes));
  }),
  getPmGroupTypesError: rest.get(`/workflow/groups/group-types/PKM`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  deleteWorkflowPermissions: rest.put(
    `/workflow/groups/delete/*`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json({}), ctx.delay(150));
    }
  ),
  deleteWorkflowPermissionsError: rest.put(
    `/workflow/groups/delete/*`,
    (req, res, ctx) => {
      return res(ctx.status(400), ctx.json({}), ctx.delay(150));
    }
  ),
  getProjectBriefs: rest.get(`/packaging-manager/project-form/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmProjectBriefs));
  }),
  getProjectBriefsEmpty: rest.get(`/packaging-manager/project-form/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json([]));
  }),
  getProjectBriefsError: rest.get(`/packaging-manager/project-form/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getAllProjectTasks: rest.get(`/packaging-manager/project-task/tasks/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmAllProjectTasks));
  }),
  getAllProjectTasksEmpty: rest.get(`/packaging-manager/project-task/tasks/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json([]));
  }),
  getProjectTasksError: rest.get(`/packaging-manager/project-task/tasks/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getPmIsAdmin: rest.get(`/workflow/packaging-manager/permissions/admin`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(true));
  }),
  getPmIsNotAdmin: rest.get(`/workflow/packaging-manager/permissions/admin`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(false));
  }),
  getPmIsAdminError: rest.get(`/workflow/packaging-manager/permissions/admin`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getPmTaskDetailsWithDateAssignedAndFiles: rest.get('/packaging-manager/project-task/task/*', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmTaskWithDueDateAssignedToAndFiles));
  }),
  getPmTaskDetailsError: rest.get('/packaging-manager/project-task/task/*', (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getPmTaskDetailsNoDateAssignedAndFiles: rest.get('/packaging-manager/project-task/task/*', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmTaskDetailsNoDueDateAssignedToOrFiles));
  }),
  getPmTaskChecklistComplete: rest.get('/packaging-manager/project-task/task/*', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmTaskChecklistComplete));
  }),
  getPmTaskCompleted: rest.get('/packaging-manager/project-task/task/*', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmTaskCompleted));
  }),
  updatePmTask: rest.put(`/packaging-manager/project-task/update/task/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  updatePmTaskError: rest.put(`/packaging-manager/project-task/update/task/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  createPmTask: rest.post(`/packaging-manager/project-task/add/task/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json("new-task-id"));
  }),
  createPmTaskError: rest.post(`/packaging-manager/project-task/add/task/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  deletePmTask: rest.delete(`/packaging-manager/project-task/delete/task/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  deletePmTaskError: rest.delete(`/packaging-manager/project-task/delete/task/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  deletePmTaskFile: rest.delete(`/packaging-manager/file/delete/task/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
  deletePmTaskFileError: rest.delete(`/packaging-manager/file/delete/task/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getProjectInstanceCurrentVersion: rest.get('/packaging-manager/project/*', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmProjectInstanceNewProduct));
  }),
  getProjectForFilesTab: rest.get('/packaging-manager/project/*', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(projectForFilesTab));
  }),
  getProjectInstancePrepaid: rest.get('/packaging-manager/project/*', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmProjectInstancePrepaid));
  }),
  getProjectInstanceCurrentVersionError: rest.get('/packaging-manager/project/*', (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  createDefaultTasks: rest.post(`/packaging-manager/project-task/default-task/add/group/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json("default-tasks-id"));
  }),
  createDefaultTasksError: rest.post(`/packaging-manager/project-task/default-task/add/group/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getTaskFormTypes: rest.get('/packaging-manager/form/blueprints/', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmFormBlueprintTypes));
  }),
  getTaskFormTypesEmpty: rest.get('/packaging-manager/form/blueprints/', (req, res, ctx) => {
    return res(ctx.status(400), ctx.json([]));
  }),
  getTaskFormTypesError: rest.get('/packaging-manager/form/blueprints/', (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getDefaultTasks: rest.get('/packaging-manager/project-task/default-task/groups', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmDefaultTaskTypes));
  }),
  getDefaultTasksError: rest.get('/packaging-manager/project-task/default-task/groups', (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getProjectFiles: rest.get('/packaging-manager/project-file/files/*', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmFilesResponse));
  }),
  getProjectFilesEmpty: rest.get('/packaging-manager/project-file/files/*', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmEmptyFilesResponse));
  }),
  getProjectFilesPage2: rest.get('/packaging-manager/project-file/files/*', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmFilesPage2Response));
  }),
  getProjectFilesAfterUploading: rest.get('/packaging-manager/project-file/files/*', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmFilesAfterUploading));
  }),
  getProjectFilesError: rest.get('/packaging-manager/project-file/files/*', (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  uploadPmFile: rest.post(`/packaging-manager/file/add/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json("uploaded-file"));
  }),
  uploadPmFileError: rest.post(`/packaging-manager/file/add/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  updatePmFile: rest.put(`/packaging-manager/file/update/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json("updated-file"));
  }),
  updatePmFileError: rest.put(`/packaging-manager/file/update/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getFileWith2Versions: rest.get('/packaging-manager/file/version/details/*', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmFileWithTwoVersions));
  }),
  getFileVersionsError: rest.get('/packaging-manager/file/version/details/*', (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  updatePmTeams: rest.put(`/packaging-manager/project/update/teams/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmProjectTeamsInitial));
  }),
  updatePmTeamsError: rest.put(`/packaging-manager/project/update/teams/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  createPmTeams: rest.post(`/packaging-manager/project/update/teams/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json("new-team-id"));
  }),
  createPmTeamsError: rest.post(`/packaging-manager/project/update/teams/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  updatePmProjectInstance: rest.put(`/packaging-manager/project/update/properties/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmProjectInstancePrepaid));
  }),
  updatePmProjectInstanceError: rest.put(`/packaging-manager/project/update/properties/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getPmMessages: rest.get('/packaging-manager/project-message/messages/*', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmMessagesResponse));
  }),
  getPmMessagesPage2: rest.get('/packaging-manager/project-message/messages/*', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmMessagesPage2));
  }),
  getPmMessagesEmpty: rest.get('/packaging-manager/project-message/messages/*', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmEmptyMessagesResponse));
  }),
  getPmMessagesError: rest.get('/packaging-manager/project-message/messages/*', (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getProjectFilesForHomeTab: rest.get('/packaging-manager/project-file/files/*', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmFilesForHomeTab));
  }),
  getProjectTasksForHomeTab: rest.get(`/packaging-manager/project-task/tasks/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmTasksForHomeTab));
  }),
  createPmMessage: rest.post(`/packaging-manager/message/add/project/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json("new-message-id"));
  }),
  createPmMessageError: rest.post(`/packaging-manager/message/add/project/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  updatePmMessage: rest.put(`/packaging-manager/message/update/project/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json("new-message-id"));
  }),
  updatePmMessageError: rest.put(`/packaging-manager/message/update/project/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  deletePmMessage: rest.delete(`/packaging-manager/message/delete/project/*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json("new-message-id"));
  }),
  deletePmMessageError: rest.delete(`/packaging-manager/message/delete/project/*`, (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getPmProjects: rest.get('/packaging-manager/project/all', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmProjectsResponse));
  }),
  getPmProjectsPage2: rest.get('/packaging-manager/project/all', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmProjectsResponsePage2));
  }),
  getPmProjectsError: rest.get('/packaging-manager/project/all', (req, res, ctx) => {
    return res(ctx.status(400), ctx.json({}));
  }),
  getPmProjectTypes: rest.get('/packaging-manager/project/blueprints', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(pmProjectTypes));
  }),
  createNewPmProjectInstance: rest.post('/packaging-manager/project/create/*', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json("project-id"));
  }),
};
