import {useEffect, useState} from "react";
import {TextButton, Button, FlexBox, Modal, Title, Body, Loader} from "components";
import {useCreateNewProjectInstanceMutation, useGetProjectTypesQuery, useUpdateProjectInstanceMutation} from "services/api/api.slice";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";
import ListGroup from "../../components/ListGroup/ListGroup";
import {ListGroupItem, ListGroupItemTitle} from "@vds/lists";
import Input from "../../components/forms/Input/Input";
import {ProjectCreateInstanceForm, ProjectTypeProps, ProjectUpdateInstanceForm} from "../../interfaces/PackagingManager";

export interface UpdateProjectModalProps {
  onClose: Function;
  onComplete: Function;
  name: string;
  projectInstanceId: string;
  status: string;
}

const UpdateProjectModal = ({onClose, onComplete, name, status, projectInstanceId}: UpdateProjectModalProps) => {
  
  const dispatch = useDispatch();
  
  const [isLoading, _isLoading] = useState<boolean>(false);
  
  const [projectName, _projectName] = useState<string>(name);
  
  const [updateProject, { data: updatedProjectDetails, isLoading: updateLoading, isError: updateError }] = useUpdateProjectInstanceMutation();
  const callUpdateProject = () => {
    if (projectInstanceId) {
      _isLoading(true);
      const projectToUpdate: ProjectUpdateInstanceForm = {
        projectInstanceId: projectInstanceId,
        name: projectName,
        status: status
      };
      updateProject(projectToUpdate)
      .unwrap()
      .then(() => {
        _isLoading(false);
        dispatch(
          setNotification({
            type: "success",
            message: `Project successfully updated`,
          })
        );
        onComplete();
      })
      .catch((error: any) => {
        _isLoading(false);
        dispatch(
          setNotification({
            type: "error",
            message: `Could not update project`,
          })
        );
        console.error(error);
      });
    }
    
  };
  
  const handleClose = () => {
    onClose();
  };
  
  const handleProjectNameChange = (e: any) => {
    _projectName(e.target.value);
  }
  
  return (
    <Modal testId="update-project-modal" open onClose={handleClose} disabled={isLoading}>
      
      <FlexBox minWidth="100%">
        <Title size="large" bold pd="0 0 2rem">
          Update {name}
        </Title>
        
        {updateLoading ? (
          <Loader containerHeight="5rem" active />
        ) : (
          <>
            <FlexBox pd="0 0 1.5rem">
              <Input
                name="projectName"
                value={projectName}
                onChange={handleProjectNameChange}
                label="Project name"
                maxWidth="60ch"
                data-testid="project-name"
              />
            </FlexBox>
            
            <FlexBox row gap="1.5rem" align="center" justify="flex-start">
              <Button
                use="primary"
                testId="submit-button"
                onClick={callUpdateProject}
                disabled={isLoading || !(projectName.trim().length > 0) || updateLoading || projectName.trim() === name.trim()}
              >
                Update project
              </Button>
              <TextButton testId="cancel-button" onClick={handleClose} disabled={isLoading}>
                Cancel
              </TextButton>
            </FlexBox>
          </>
        )}
        
      </FlexBox>
      
      
    </Modal>
  );
};

export default UpdateProjectModal;
