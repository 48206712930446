import { useState } from "react";
import {TextButton, Button, FlexBox, Modal, Title, Body, Loader} from "components";
import {useDeleteFormFileMutation, useDeleteProjectFileMutation, useDeleteTaskFileMutation, useDeleteTaskMutation} from "services/api/api.slice";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";


export interface DeletePackagingManagerFileModalProps {
  parentFileId: string;
  instanceId: string;
  onClose: Function;
  onComplete: Function;
  fileName: string;
  instanceType: "project" | "task" | "form";
  projectInstanceId: string;
}

const DeletePackagingManagerFileModal = ({
                           parentFileId,
                           instanceId,
                           onClose,
                           onComplete,
                           fileName,
                           instanceType,
                           projectInstanceId,
                         }: DeletePackagingManagerFileModalProps) => {
  
  const dispatch = useDispatch();
  
  const [deleteTaskFile, {isLoading: deleteTaskFileLoading}] = useDeleteTaskFileMutation();
  const [deleteProjectFile, {isLoading: deleteProjectFileLoading}] = useDeleteProjectFileMutation();
  const [deleteFormFile, {isLoading: deleteFormFileLoading}] = useDeleteFormFileMutation();
  const [isLoading, _isLoading] = useState<boolean>(false);
  const callDeleteFile = () => {
    _isLoading(true);
    if (instanceType === "project") {
      deleteProjectFile({projectInstanceId: instanceId, parentFileId: parentFileId})
      .unwrap()
      .then(() => {
        dispatch(
          setNotification({
            type: "success",
            message: `File successfully deleted`,
          })
        );
        onComplete();
      })
      .catch((error: any) => {
        dispatch(
          setNotification({
            type: "error",
            message: `Could not delete file`,
          })
        );
        console.error(error);
      })
      .finally(() => {
        _isLoading(false);
      });
    } else if (instanceType === "task") {
      deleteTaskFile({projectInstanceId: projectInstanceId, taskInstanceId: instanceId, parentFileId: parentFileId})
      .unwrap()
      .then(() => {
        dispatch(
          setNotification({
            type: "success",
            message: `File successfully deleted`,
          })
        );
        onComplete();
      })
      .catch((error: any) => {
        dispatch(
          setNotification({
            type: "error",
            message: `Could not delete file`,
          })
        );
        console.error(error);
      })
      .finally(() => {
        _isLoading(false);
      });
    } else if (instanceType === "form") {
      deleteFormFile({projectInstanceId: projectInstanceId, formInstanceId: instanceId, parentFileId: parentFileId})
      .unwrap()
      .then(() => {
        dispatch(
          setNotification({
            type: "success",
            message: `File successfully deleted`,
          })
        );
        onComplete();
      })
      .catch((error: any) => {
        dispatch(
          setNotification({
            type: "error",
            message: `Could not delete file`,
          })
        );
        console.error(error);
      })
      .finally(() => {
        _isLoading(false);
      });
    }
    
  };
  
  const handleClose = () => {
    onClose();
  };
  
  return (
    <Modal open={true} testId="delete-pm-file-modal" onClose={handleClose} disabled={isLoading || deleteTaskFileLoading}>
      <FlexBox maxWidth="100%" className="delete-file-modal">
        <Title size="large" bold pd="0 0 0.5rem">
          Delete file:
        </Title>
        <Title size="small" bold pd="0 0 2rem">
           {fileName}
        </Title>
        {isLoading || deleteTaskFileLoading ? (
          <FlexBox align="center" justify="center">
            <Loader active containerHeight="10rem" />
          </FlexBox>
        ) : (
          <>
            <Body size="large" pd="0 0 2rem">
              Are you sure you want to delete this file? Deleted files are permanently removed and will no longer be accessible.
            </Body>
            <FlexBox row gap="1.5rem" align="center" justify="flex-start">
              <Button testId="submit-button" use="primary" onClick={callDeleteFile} disabled={isLoading || deleteTaskFileLoading || deleteProjectFileLoading || deleteFormFileLoading}>
                Delete file
              </Button>
              <TextButton data-testid="cancel-button" onClick={handleClose} disabled={isLoading || deleteTaskFileLoading || deleteProjectFileLoading || deleteFormFileLoading}>
                Cancel
              </TextButton>
            </FlexBox>
          </>
        )}
      
      </FlexBox>
    </Modal>
  );
};

export default DeletePackagingManagerFileModal;
