import styled from 'styled-components';
import { ColorTokens } from '@vds-tokens/color';

const RedText = styled.span.withConfig({
  displayName: "RedText",
  componentId: "VDS__sc-p8psq1-0"
})`
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  color: ${ColorTokens.palette.red.value};
`;

export { RedText as default };
