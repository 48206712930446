import { useRef } from "react";
import Button from "../Button/Button";
import CaretButton from "../CaretButton/CaretButton";


export interface FileUploadButtonProps {
  onClick: Function;
  disabled?: boolean;
  caret?: boolean;
  children?: any;
  size?: "medium" | "small" | "large";
  caretPosition?: "left" | "right" | "";
  id?: string;
  label?: string;
  mg?: string;
  maxWidth?: string;
  error?: any;
  use?: "primary" | "secondary";
  testId?: string;
}

const FileUploadButton = ({
  onClick,
  disabled,
  caret,
  children,
  caretPosition,
  size,
  id,
  label,
  mg,
  maxWidth,
  error,
  use,
  testId,
}: any) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null),
    handleClick = (_: any) => {
      hiddenFileInput.current?.click();
    },
    handleChange = (event: any) => {
      const fileUploaded = event.target.files[0];
      onClick(fileUploaded);
    };
  
  return caret ? (
    <>
      <CaretButton testId={testId} caretPosition={caretPosition} size={size} onClick={handleClick} disabled={disabled}>
        {children}
      </CaretButton>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        data-testid="hidden-file-input"
        style={{ display: "none" }}
      />
    </>
  ) : (
    // : uploadArea ? (
    //   <ButtonWrapper disabled={disabled} maxWidth={maxWidth} error={error} mg={mg}>
    //     <label htmlFor={id}><Body size="small">{label ? label : "Upload file(s)"}</Body></label>
    //     <button onClick={handleClick} disabled={disabled}>
    //       {children}
    //     </button>
    //     <input
    //       id={id}
    //       type="file"
    //       ref={hiddenFileInput}
    //       onChange={handleChange}
    //       style={{ display: "none" }}
    //     />
    //   </ButtonWrapper>
    // )
    <>
      <Button testId={testId} use={use} size={size === "small" ? "small" : "large"} onClick={handleClick} disabled={disabled} type="button">
        {children}
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        data-testid="hidden-file-input"
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  );
};

export default FileUploadButton;
