import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TitleLockupTitle from './TitleLockupTitle.js';
import TitleLockupSubtitle from './TitleLockupSubtitle.js';
import TitleLockupEyebrow from './TitleLockupEyebrow.js';
import { withVDSManager } from '@vds-core/utilities';
import { _findTooltipSize } from '../utils/TooltipSizeCalc.js';

const propTypes = {
  /**
   * If data is not provided, TitleLockup subcomponents will be rendered based on it's children.
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
  /**
   * If provided, TitleLockup subcomponents will be rendered based on this data.
   * @typeName TitleLockupData
   */
  data: PropTypes.shape({
    eyebrow: PropTypes.shape({
      size: PropTypes.oneOf(['bodySmall', 'bodyMedium', 'bodyLarge', 'titleSmall', 'titleMedium', 'titleLarge']),
      bold: PropTypes.bool,
      primitive: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'p']),
      children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
      tooltip: PropTypes.shape({
        id: PropTypes.string,
        disabled: PropTypes.bool,
        containerId: PropTypes.string,
        ariaLabel: PropTypes.string,
        size: PropTypes.oneOf(['small', 'medium']),
        iconFillColor: PropTypes.oneOfType([PropTypes.oneOf(['primary', 'secondary', 'brandHighlight']), PropTypes.string]),
        children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
        closeButtonText: PropTypes.string,
        'data-track': PropTypes.string,
        'data-track-ignore': PropTypes.string,
        'data-analyticstrack': PropTypes.string,
        'data-clickstream': PropTypes.string
      })
    }),
    title: PropTypes.shape({
      size: PropTypes.oneOf(['titleSmall', 'titleMedium', 'titleLarge', 'titleXLarge', 'title2XLarge', 'featureXSmall', 'featureSmall', 'featureMedium']),
      numberOfLines: PropTypes.number,
      surface: PropTypes.oneOf(['light', 'dark']),
      bold: PropTypes.bool,
      primitive: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'p']),
      children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
      tooltip: PropTypes.shape({
        id: PropTypes.string,
        disabled: PropTypes.bool,
        containerId: PropTypes.string,
        ariaLabel: PropTypes.string,
        size: PropTypes.oneOf(['small', 'medium']),
        iconFillColor: PropTypes.oneOfType([PropTypes.oneOf(['primary', 'secondary', 'brandHighlight']), PropTypes.string]),
        children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
        closeButtonText: PropTypes.string,
        'data-track': PropTypes.string,
        'data-track-ignore': PropTypes.string,
        'data-analyticstrack': PropTypes.string,
        'data-clickstream': PropTypes.string
      })
    }),
    subtitle: PropTypes.shape({
      size: PropTypes.oneOf(['bodySmall', 'bodyMedium', 'bodyLarge', 'titleSmall', 'titleMedium', 'titleLarge']),
      color: PropTypes.string,
      numberOfLines: PropTypes.number,
      surface: PropTypes.oneOf(['light', 'dark']),
      primitive: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'p']),
      children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
      tooltip: PropTypes.shape({
        id: PropTypes.string,
        disabled: PropTypes.bool,
        containerId: PropTypes.string,
        ariaLabel: PropTypes.string,
        size: PropTypes.oneOf(['small', 'medium']),
        iconFillColor: PropTypes.oneOfType([PropTypes.oneOf(['primary', 'secondary', 'brandHighlight']), PropTypes.string]),
        children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
        closeButtonText: PropTypes.string,
        'data-track': PropTypes.string,
        'data-track-ignore': PropTypes.string,
        'data-analyticstrack': PropTypes.string,
        'data-clickstream': PropTypes.string
      })
    })
  }),
  /**
   * Aligns TitleLockup'S subcomponent's text.
   */
  textAlignment: PropTypes.oneOf(['center', 'left']),
  /**
   * ID of component.
   */
  id: PropTypes.string,
  /**
   * @ignore
   */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  textAlignment: 'left',
  surface: 'light'
};
const TitleLockupWrapper = styled.div.withConfig({
  displayName: "TitleLockupWrapper",
  componentId: "VDS__sc-1kiwzla-0"
})`
  display: flex;
  flex-direction: column;
  text-align: ${({
  textAlignment
}) => textAlignment};
  width: 100%;
`;
const TitleLockup = props => {
  const {
    children,
    data,
    textAlignment,
    viewport,
    id,
    surface
  } = props;
  const _findTitleSize = () => {
    let titleSize;
    return React.Children.map(children, (child, index) => {
      const childType = child && child.type && child.type.displayName ? child.type.displayName : '';
      if (childType === 'TitleLockupTitle') {
        titleSize = child.props.size;
      }
      return titleSize;
    });
  };
  const _findSubtitleSize = () => {
    let textSize;
    return React.Children.map(children, (child, index) => {
      const childType = child && child.type && child.type.displayName ? child.type.displayName : '';
      if (childType === 'TitleLockupSubtitle') {
        textSize = child.props.size;
      }
      return textSize;
    });
  };
  const _findEyebrowSize = () => {
    let textSize;
    return React.Children.map(children, (child, index) => {
      const childType = child && child.type && child.type.displayName ? child.type.displayName : '';
      if (childType === 'TitleLockupEyebrow') {
        textSize = child.props.size;
      }
      return textSize;
    });
  };
  const _findControllingSize = () => {
    let eyebrowSize = _findEyebrowSize();
    let subtitleSize = _findSubtitleSize();
    let realSubtitleSize = data && data.subtitle && data.subtitle.size ? data.subtitle.size : subtitleSize && subtitleSize.length > 0 ? subtitleSize[0] : undefined;
    let realEyebrowSize = data && data.eyebrow && data.eyebrow.size ? data.eyebrow.size : eyebrowSize && eyebrowSize.length > 0 ? eyebrowSize[0] : undefined;
    if (realSubtitleSize !== undefined && realEyebrowSize === undefined) {
      return realSubtitleSize;
    } else if (realEyebrowSize !== undefined && realSubtitleSize === undefined) {
      return realEyebrowSize;
    } else if (realEyebrowSize !== undefined && realSubtitleSize !== undefined) {
      return realSubtitleSize;
    }
  };
  const _findTextSize = () => {
    let titleSize = _findTitleSize();
    let realTitleSize = data && data.title && data.title.size ? data.title.size : titleSize && titleSize.length > 0 ? titleSize[0] : 'titleLarge';
    let controlingSize = _findControllingSize();
    const desktopMap = {
      titleSmall: ['bodySmall', 'bodyMedium', 'bodyLarge'],
      titleMedium: ['bodySmall', 'bodyMedium', 'bodyLarge'],
      titleLarge: ['bodyLarge', 'bodySmall', 'bodyMedium', 'titleSmall'],
      titleXLarge: ['titleMedium', 'bodyLarge'],
      title2XLarge: ['titleMedium', 'titleLarge', 'bodyLarge'],
      featureXSmall: ['titleMedium', 'titleLarge', 'bodyLarge'],
      featureSmall: ['titleLarge', 'titleMedium', 'bodyLarge'],
      featureMedium: ['titleLarge', 'titleMedium', 'bodyLarge']
    };
    const mobileMap = {
      titleSmall: ['bodySmall', 'bodyMedium'],
      titleMedium: ['bodySmall', 'bodyMedium', 'bodyLarge'],
      titleLarge: ['bodyLarge', 'bodySmall', 'bodyMedium'],
      titleXLarge: ['bodyLarge', 'bodySmall', 'bodyMedium', 'titleMedium'],
      title2XLarge: ['bodyLarge', 'bodyMedium', 'titleMedium'],
      featureXSmall: ['bodyLarge', 'bodyMedium', 'titleMedium'],
      featureSmall: ['titleLarge', 'bodyLarge'],
      featureMedium: ['titleLarge', 'titleXLarge', 'bodyLarge']
    };
    let canIUseMySubtitleDesktop = !!desktopMap[realTitleSize].includes(controlingSize);
    let canIUseMySubtitleMobile = !!mobileMap[realTitleSize].includes(controlingSize);
    if (!canIUseMySubtitleMobile && viewport === 'mobile') {
      const titleGroup = mobileMap[realTitleSize];
      const defaultSubtitle = titleGroup[0];
      controlingSize = defaultSubtitle;
    }
    if (!canIUseMySubtitleDesktop && viewport !== 'mobile') {
      const titleGroup = desktopMap[realTitleSize];
      const defaultSubtitle = titleGroup[0];
      controlingSize = defaultSubtitle;
    } else {
      controlingSize = controlingSize;
    }
    return controlingSize;
  };
  const _findEyebrowPadding = () => {
    let titleSize = _findTitleSize();
    let realTitleSize = data && data.title && data.title.size ? data.title.size : titleSize && titleSize.length > 0 ? titleSize[0] : 'titleLarge';
    let otherSize = _findTextSize();
    const sizeList = ['titleSmall', 'titleMedium', 'titleLarge', 'titleXLarge', 'title2XLarge', 'featureXSmall', 'featureSmall', 'featureMedium'];
    let padding;
    if (viewport == 'mobile') {
      if (sizeList.indexOf(realTitleSize) < 3) {
        padding = '8px';
      } else if (sizeList.indexOf(realTitleSize) > 6) {
        padding = otherSize === 'bodyLarge' ? '12px' : '16px';
      } else {
        padding = '12px';
      }
      return padding;
    } else {
      if (sizeList.indexOf(realTitleSize) < 2) {
        padding = '8px';
      } else if (sizeList.indexOf(realTitleSize) > 3) {
        padding = otherSize === 'bodyLarge' ? '12px' : '16px';
      } else {
        padding = '12px';
      }
      return padding;
    }
  };
  // Check to see if a subcomponent of TitleLockup is passed to render paddingBottom of either titleLockupEyebrow or TitleLockupTitle
  const _doesChildExist = (data, childName) => {
    if (!data) return null;
    if (Array.isArray(data)) {
      return data.map(child => child && child.type && child.type.displayName ? child.type.displayName : '').indexOf(childName) !== -1;
    }
    if (typeof data === 'object') {
      return data.type && data.type.displayName && data.type.displayName === childName;
    }
  };
  const _renderChildren = () => {
    let titleSize = _findTitleSize();
    let newFontSize = _findTextSize();
    let eyeBrowPadding = _findEyebrowPadding();
    if (titleSize === undefined || !titleSize) return props.children;
    return React.Children.map(children, (child, index) => {
      const childType = child && child.type && child.type.displayName ? child.type.displayName : '';
      if (childType === 'TitleLockupSubtitle') {
        return React.cloneElement(child, Object.assign(Object.assign({}, child.props), {
          size: newFontSize,
          viewport: viewport,
          surface: surface || child.props.surface
        }));
      } else if (childType === 'TitleLockupTitle') {
        return React.cloneElement(child, Object.assign(Object.assign({}, child.props), {
          viewport: viewport,
          surface: surface || child.props.surface,
          isStandAlone: !_doesChildExist(children, 'TitleLockupSubtitle')
        }));
      } else if (childType === 'TitleLockupEyebrow') {
        return React.cloneElement(child, Object.assign(Object.assign({}, child.props), {
          size: newFontSize,
          viewport: viewport,
          paddingBottom: eyeBrowPadding,
          surface: surface || child.props.surface,
          isStandAlone: !(_doesChildExist(children, 'TitleLockupTitle') || _doesChildExist(children, 'TitleLockupSubtitle'))
        }));
      } else return child;
    });
  };
  const _renderItemData = () => {
    let newFontSize = _findTextSize();
    let eyeBrowPadding = _findEyebrowPadding();
    return React.createElement(React.Fragment, null, !!data.eyebrow && React.createElement(TitleLockupEyebrow, Object.assign({}, data.eyebrow, {
      isStandAlone: !data.title && !data.subtitle,
      paddingBottom: eyeBrowPadding,
      size: newFontSize
    }, data.eyebrow.tooltip && {
      tooltip: Object.assign(Object.assign({}, data.eyebrow.tooltip), {
        size: _findTooltipSize(data.eyebrow.tooltip.size ? data.eyebrow.tooltip.size : undefined, newFontSize, viewport)
      })
    }, {
      viewport: viewport,
      surface: data && data.eyebrow && data.eyebrow.surface || surface
    })), !!data.title && React.createElement(TitleLockupTitle, Object.assign({}, data.title, data.title.tooltip && {
      tooltip: Object.assign(Object.assign({}, data.title.tooltip), {
        size: _findTooltipSize(data.title.tooltip.size ? data.title.tooltip.size : undefined, data.title.size, viewport)
      })
    }, {
      isStandAlone: !data.subtitle,
      size: data.title.size,
      viewport: viewport,
      surface: data && data.title && data.title.surface || surface
    })), !!data.subtitle && React.createElement(TitleLockupSubtitle, Object.assign({}, data.subtitle, {
      size: newFontSize
    }, data.subtitle.tooltip && {
      tooltip: Object.assign(Object.assign({}, data.subtitle.tooltip), {
        size: _findTooltipSize(data.subtitle.tooltip.size ? data.subtitle.tooltip.size : undefined, newFontSize, viewport)
      })
    }, {
      viewport: viewport,
      surface: data && data.subtitle && data.subtitle.surface || surface
    })));
  };
  return React.createElement(TitleLockupWrapper, {
    textAlignment: textAlignment,
    id: id,
    surface: surface
  }, !data && _renderChildren(), data && _renderItemData());
};
var TitleLockup$1 = withVDSManager(TitleLockup);
TitleLockup.propTypes = propTypes;
TitleLockup.defaultProps = defaultProps;

export { TitleLockup$1 as default };
