import { useState } from "react";
import { DeleteModalProps, ManagedUser } from "interfaces";
import {TextButton, Button, FlexBox, Modal, Title, Body, Loader} from "components";
import { useDeleteTaskMutation } from "services/api/api.slice";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";


export interface DeleteTaskModalProps {
  projectInstanceId: string;
  taskInstanceId: string;
  onClose: Function;
  onComplete: Function;
  taskName: string;
}

const DeleteTaskModal = ({
  projectInstanceId,
  taskInstanceId,
  onClose,
  onComplete,
  taskName,
}: DeleteTaskModalProps) => {
  
  const dispatch = useDispatch();
  
  const [deleteTask, {isLoading: deleteTaskLoading}] = useDeleteTaskMutation();
  const [isLoading, _isLoading] = useState<boolean>(false);
  const callDeleteTask = () => {
    _isLoading(true);
    deleteTask({projectInstanceId: projectInstanceId, taskInstanceId: taskInstanceId})
    .unwrap()
    .then(() => {
      dispatch(
        setNotification({
          type: "success",
          message: `Task successfully deleted`,
        })
      );
      onComplete();
    })
    .catch((error: any) => {
      dispatch(
        setNotification({
          type: "error",
          message: `Could not delete task`,
        })
      );
      console.error(error);
    })
    .finally(() => {
      _isLoading(false);
    });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal open={true} testId="delete-task-modal" onClose={handleClose} disabled={isLoading || deleteTaskLoading}>
      <FlexBox>
        <Title size="large" bold pd="0 0 2rem">
          {`Delete task: ${taskName}`}
        </Title>
        {isLoading || deleteTaskLoading ? (
          <FlexBox align="center" justify="center">
            <Loader active containerHeight="10rem" />
          </FlexBox>
        ) : (
          <>
            <Body size="large" pd="0 0 2rem">
              Are you sure you want to delete this task? Deleted tasks are permanently removed and will no longer be accessible.
            </Body>
            <FlexBox row gap="1.5rem" align="center" justify="flex-start">
              <Button testId="submit-delete-task-button" use="primary" onClick={callDeleteTask} disabled={isLoading || deleteTaskLoading}>
                Delete task
              </Button>
              <TextButton testId="cancel-button" onClick={handleClose} disabled={isLoading || deleteTaskLoading}>
                Cancel
              </TextButton>
            </FlexBox>
          </>
        )}
        
      </FlexBox>
    </Modal>
  );
};

export default DeleteTaskModal;
